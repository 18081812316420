import Hero from "../Components/Hero";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";

function Landing() {
  return (
    <div className="App">
      <Nav />
      <Hero />
      <Footer />
    </div>
  );
}

export default Landing;
