import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import TerpeneComponent from "../Components/TerpeneComponent";
export const Terpene = () => {
  return (
    <>
      <Nav />
      <TerpeneComponent />
      <Footer />
    </>
  );
};

export default Terpene;
