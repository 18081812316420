import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "./Pages/Landing";
import Process from "./Pages/Process";
import Analysis from "./Pages/Analysis";
import Lab from "./Pages/Lab";
import Pricing from "./Pages/Pricing";
import ContactUS from "./Pages/ContactUS"
import Potency from "./Pages/Potency";
import Safety from "./Pages/Safety"
import Moisture from "./Pages/Moisture"
import Terpene from "./Pages/Terpene"
import FAQ from "./Pages/FAQ";
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-213420110-1";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Landing />
        </Route>
        <Route path="/Landing">
          <Landing />
        </Route>
        <Route path="/Process">
          <Process />
        </Route>
        <Route path="/Analysis">
          <Analysis />
        </Route>
        <Route path="/Lab">
          <Lab />
        </Route>
        <Route path="/Pricing">
          <Pricing />
        </Route>
        <Route path="/ContactUS">
          <ContactUS />
        </Route>
        <Route path="/Potency">
          <Potency />
        </Route>
        <Route path="/Safety">
          <Safety />
        </Route>
        <Route path="/Moisture">
          <Moisture />
        </Route>
        <Route path="/Terpene">
          <Terpene />
        </Route>
        <Route path="/FAQ">
          <FAQ />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
