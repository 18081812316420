import img from "../Images/process.webp";
export const Steps = () => {
  return (
    <>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="container px-5 py-6 mx-auto pb-5 ">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white font-Montserrat">
              How we Work
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base font-Roboto">
              Getting your sample analysed is easy. Just follow the steps below.
            </p>
          </div>
        </div>
        <div className="container px-5 py-24 pt-1 mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
              <div className="flex relative pb-12">
                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-800 pointer-events-none" />
                </div>
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                    1
                  </div>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider font-Montserrat">
                    CREATE AN ACCOUNT
                  </h2>
                  <p className="leading-relaxed font-Roboto">
                    Sign-up for an account on our Customer Portal. Using our
                    Customer Portal you can manage all activities related to
                    your interaction with us. You can do sign-up by by clicking
                    on the Login button at the top right of the screen and
                    following the on-screen instructions
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-800 pointer-events-none" />
                </div>
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                    2
                  </div>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider font-Montserrat">
                    REQUEST SAMPLE ANALYSIS
                  </h2>
                  <p className="leading-relaxed font-Roboto">
                    Once your account has been created you can Request a sample
                    analysis but clicking on the Request Sample Analysis button.
                    This will guide you through the process of getting your
                    sample ready for transport and labelling the sample to
                    ensure consistency.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-1 bg-gray-800 pointer-events-none" />
                </div>
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-sm">
                    3
                  </div>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider font-Montserrat">
                    SEND US YOUR SAMPLE
                  </h2>
                  <p className="leading-relaxed font-Roboto">
                    Once your sample is ready to be sent, place it in an
                    envelope and courier us your sample. We are also able to
                    offer free collection services within KZN. This offer is
                    valid for a limited time only.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">

                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider font-Montserrat">
                    GET YOUR RESULTS
                  </h2>
                  <p className="leading-relaxed font-Roboto">
                    Then sit back and wait until your results are ready. You can
                    login to the Customer Portal to get your results.
                  </p>
                </div>
              </div>
            </div>
            <img
              className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12"
              src={img}
              alt="step"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Steps;
