import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import PricingComponent from "../Components/PricingComponent";

export const Pricing = () => {
  return (
    <>
      <Nav />
      <PricingComponent />
      <Footer />
    </>
  );
};

export default Pricing;
