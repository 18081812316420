import AnalysisTable from "./AnalysisTable";
import moistImg from "../Images/04.png";
export const PotencyComponent = () => {
  return (
    <>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="flex flex-col text-center w-full px-5 py-6">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white font-Montserrat">
            Potency
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Our Equipment can quantify 13
            of the most common Cannabinoid's found in the Cannabis plant. See the table below for a complete list.
          </p>
          <div className="container mx-auto flex px-5 py-6 items-center justify-center flex-col">
          <img
            alt="content"
            className="object-cover object-center h-96 w-96"
            src={moistImg}
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-2xl text-1xl mb-4 font-medium text-white font-Montserrat">
              Potency Analysis in Cannabis
            </h1>
            <p className="leading-relaxed mb-8 font-Roboto">
            Compound profiling and potency analysis aids growers, processors, dispensaries, and quality control
            laboratories by identifying the cannabinoids and terpenoids in their samples and measuring their strength.
            Identifying the compound profiles of different strains, and their associated strength, is necessary for
            accurate labelling of both medicinal and recreational products. While product safety is paramount, knowing
            the chemical makeup of the product, allows doctors to make medical recommendations, and patients to make
            informed decisions about the products that best suit their needs.
            <br/>
            <br/>

            Our skilled laboratory scientists can quantify the potency and unique cannabinoid profile of your final product.
            Our potency analysis measures 13 of the most popular cannabinoid:
            </p>
          </div>
          </div>
        </div>
      </section>
      <AnalysisTable />
    </>
  );
};

export default PotencyComponent;
