import React, { useState } from "react";
import img from "../Images/Capture.png";
import Modal from "react-modal";

Modal.setAppElement("#root");
export const Nav = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      visibility: "hidden",
    },
  };
  return (
    <>
      <nav className="relative px-4 py-4 flex justify-between items-center bg-gray-900 border-b-2 border-gray-800">
        <a className=" flex text-3xl leading-none items-center" href="/landing">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={img}
          />
          <span className="ml-3 text-3xl font-Montserrat font-light text-white ">
            Sentinel
          </span>
        </a>
        <div className="lg:hidden">
          <button className="navbar-burger flex items-center text-white p-3">
            <svg
              className="block h-4 w-4 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Mobile menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">
          <li>
            <a
              className="text-lg text-white hover:text-gray-500 font-Roboto font-extralight"
              href="/Analysis"
            >
              Analytics
            </a>
          </li>
          <li className="text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              className="w-4 h-4 current-fill"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </li>
          <li>
            <a
              className="text-lg text-white  hover:text-gray-500 font-Roboto"
              href="/Process"
            >
              Process
            </a>
          </li>
          <li className="text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              className="w-4 h-4 current-fill"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </li>
          <li>
            <a
              className="text-lg text-white hover:text-gray-500 font-Roboto"
              href="/Lab"
            >
              Lab
            </a>
          </li>
          <li className="text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              className="w-4 h-4 current-fill"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </li>
          <li>
            <a
              className="text-lg text-white hover:text-gray-500 font-Roboto"
              href="/Pricing"
            >
              Pricing
            </a>
          </li>
          <li className="text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              className="w-4 h-4 current-fill"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </li>
          <li>
            <a
              className="text-lg text-white hover:text-gray-500 font-Roboto"
              href="ContactUS"
            >
              Contact Us
            </a>
          </li>
        </ul>
        <button
          onClick={() => setModalIsOpen(true)}
          className="lg:mt-2 xl:mt-0 flex-shrink-0  text-white bg-indigo-500 border-0 focus:outline-none hover:bg-indigo-600 rounded hidden lg:inline-block lg:ml-auto lg:mr-3 py-2 px-6 text-sm transition duration-200 font-Montserrat"
        >
          Login
        </button>
      </nav>
      <div className="navbar-menu relative z-50 hidden">
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" />
        <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-gray-900 border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <a
              className="mr-auto flex items-center text-3xl leading-none"
              href="/landing"
            >
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src={img}
              />
              <span className="ml-3 text-xl font-Montserrat text-white ">
                Sentinel
              </span>
            </a>
            <button className="navbar-close">
              <svg
                className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div>
            <ul className="text-3xl font-Montserrat">
              <li className="mb-1">
                <a
                  className="block p-4 text-lg text-white hover:bg-blue-50 hover:text-blue-600 rounded"
                  href="/Analysis"
                >
                  Analytics
                </a>
              </li>
              <li className="mb-1">
                <a
                  className="block p-4 text-lg text-white hover:bg-blue-50 hover:text-blue-600 rounded"
                  href="/Process"
                >
                  Process
                </a>
              </li>
              <li className="mb-1">
                <a
                  className="block p-4 text-lg text-white hover:bg-blue-50 hover:text-blue-600 rounded"
                  href="/Lab"
                >
                  Lab
                </a>
              </li>
              <li className="mb-1">
                <a
                  className="block p-4 text-lg text-white hover:bg-blue-50 hover:text-blue-600 rounded"
                  href="/Pricing"
                >
                  Pricing
                </a>
              </li>
              <li className="mb-1">
                <a
                  className="block p-4 text-lg text-white hover:bg-blue-50 hover:text-blue-600 rounded"
                  href="/ContactUS"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6">
              <a
                className="block px-4 py-3 mb-3 text-white text-lg text-center font-semibold leading-none bg-indigo-500 hover:bg-white hover:text-indigo-500 rounded"
                onClick={() => setModalIsOpen(true)}
              >
                Login
              </a>
            </div>
          </div>
        </nav>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <div className=" visible inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                {/* Heroicon name: outline/exclamation */}
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Coming Soon...
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Our Customer Portal will be available soon.
                    <br />
                    In the interim please e-mail us on: <span className='text-indigo-500 text-xl'>info@saiclab.co.za</span>
                    <br />
                    We will provide address details and sample packing
                    instructions.
                    <br />
                    Results will be e-mailed to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => setModalIsOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Nav;
