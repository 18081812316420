import terpImg from "../Images/terpenes.png";
export const TerpeneComponent = () => {
  return (
    <>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="flex flex-col text-center w-full pt-7">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white font-Montserrat">
            Terpene
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Terpene compounds are naturally occurring aromatic compounds that
            give cannabis its unique flavor and fragrance. Aside from their
            aromatic properties, terpenes have advantageous health benefits.
            <br/>

            Some of the more popular terpenes include:
          </p>
        </div>
      </section>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
              <div className="flex relative pb-12">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider">
                    Myrcene
                  </h2>
                  <p className="leading-relaxed">
                    Myrcene is the most abundant terpene in cannabis, which is
                    where it’s mostly found in nature. In fact, one study showed
                    that myrcene makes up as much as 65% of total terpene
                    profile in some strains. Myrcene smell often reminds of
                    earthy, musky notes, resembling cloves. Also, it has a
                    fruity, red grape-like aroma
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider">
                    Limonene
                  </h2>
                  <p className="leading-relaxed">
                    Limonene is the second most abundant terpene in all cannabis
                    strains, but not all strains necessarily have it. As its
                    name says, limonene gives strains a citrusy smell that
                    resembles lemons, which is no surprise as all citrus fruits
                    contain large amounts of this compound. Limonene is used in
                    cosmetics and also in cleaning products. For therapeutic
                    purposes, limonene is known to improve mood and reduce
                    stress. Researchers also found it to have antifungal and
                    antibacterial properties and one research even found it to
                    have a role in reducing tumor size.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <circle cx={12} cy={5} r={3} />
                    <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider">
                    Linalool
                  </h2>
                  <p className="leading-relaxed">
                    This terpene is the most responsible for the recognizable
                    marijuana smell with its spicy and floral notes. Linalool is
                    also found in lavender, mint, cinnamon and coriander. What’s
                    interesting is that just like those aromatic herbs, it has
                    very strong sedative and relaxing properties.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-12">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                    <circle cx={12} cy={7} r={4} />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider">
                    Caryophyllene
                  </h2>
                  <p className="leading-relaxed">
                    Best known for its spicy and peppery note, caryophyllene is
                    also found in black pepper, cinnamon, cloves, and spices
                    like oregano, basil and rosemary. Beta-caryophyllene binds
                    to CB2 receptors, which makes it an ingredient in
                    anti-inflammatory topicals and creams. Caryophyllene is the
                    only terpene that binds to cannabinoid receptors. Besides
                    its analgesic and anti-anxiety properties, some studies have
                    found that caryophyllene has some very promising properties
                    when it comes to alcoholism rehabilitation.
                  </p>
                </div>
              </div>
              <div className="flex relative">
                <div className="flex-shrink-0 w-10 h-10 rounded-full bg-indigo-500 inline-flex items-center justify-center text-white relative z-10">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                </div>
                <div className="flex-grow pl-4">
                  <h2 className="font-medium title-font text-sm text-white mb-1 tracking-wider">
                    Alpha-pinene and Beta-pinene
                  </h2>
                  <p className="leading-relaxed">
                    These two cannabis terpenes smell like pine trees and that’s
                    also where they can be found in large amounts. Other plants
                    rich in pinene include rosemary, orange peels, basil,
                    parsley and cannabis of course. Like many other, pinene
                    terpenes have an anti-inflammatory effect on humans. But
                    more importantly, they help improve airflow and respiratory
                    functions, while also helping to reduce memory loss related
                    to THC.
                  </p>
                </div>
              </div>
            </div>
            <img
              className="lg:w-3/5 md:w-1/2 object-contain object-top rounded-lg md:mt-0 mt-12"
              src={terpImg}
              alt="step"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default TerpeneComponent;
