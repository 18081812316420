import medImg from "../Images/03.png";
import moistImg from "../Images/02.png";
import potImg from "../Images/04.png";
import terpImg from "../Images/01.png";
export const AnalysisComponent = () => {
  return (
    <>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="container px-5 py-6 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white font-Montserrat">
              Analysis
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              We are able to offer the full spectrum of Cannabis analysis to meet all efficacy and quality requirements.
            </p>
          </div>
          <div className="flex flex-wrap text-center">
            <div className="sm:w-1/2 mb-10 border-2 border-gray-700 px-2 rounded-lg ">
              <div className="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-contain object-center h-full w-full"
                  src={potImg}
                />
              </div>
              <h2 className="title-font text-2xl font-medium text-white mt-6 mb-3 font-Montserrat">
                Potency Testing
              </h2>
              <p className="leading-relaxed text-base font-Roboto">
                Our Potency analysis solution uses High-Performance Liquid
                Chromatography (HPLC) and advanced methodology to accurately quantify the
                cannabinoids present in your cannabis product.
              </p>
              <a href='/potency' className="mt-3 text-indigo-400 inline-flex items-center pb-4">
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>
                  </a>
            </div>
            <div className="sm:w-1/2 mb-10 px-4 border-2 border-gray-700 rounded-lg">
              <div className="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-contain object-center h-full w-full"
                  src={moistImg}
                />
              </div>
              <h2 className="title-font text-2xl font-medium text-white mt-6 mb-3 font-Montserrat">
                Moisture Content
              </h2>
              <p className="leading-relaxed text-base font-Roboto">
                Moisture Content determination is a crucial indicator of
                potential microbial contamination. Moisture not only effects
                shelf-life and taste, but can also affect the Cannaboid profile
                of the final product.
              </p>
              <a  href='/moisture' className="mt-3 text-indigo-400 inline-flex items-center pb-4">
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>
                  </a>
            </div>
            <div className="sm:w-1/2 mb-10 px-4 border-2 border-gray-700 rounded-lg ">
              <div className="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  className="object-contain object-center h-full w-full"
                  src={terpImg}
                />
              </div>
              <h2 className="title-font text-2xl font-medium text-white mt-6 mb-3 font-Montserrat">
                Terpene Profile
              </h2>
              <p className="leading-relaxed text-base font-Roboto">
                Terpene compounds are naturally occurring aromatic compounds
                that give cannabis its unique flavor and fragrance. Aside from
                their aromatic properties, terpenes have advantageous health
                benefits.
              </p>
              <a  href='/terpene' className="mt-3 text-indigo-400 inline-flex items-center pb-4">
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>
                  </a>
            </div>
            <div className="sm:w-1/2 mb-10 px-4 border-2 border-gray-700 rounded-lg ">
              <div className="rounded-lg h-64 overflow-hidden">
                <img
                  alt="content"
                  className=" object-scale-down object-center h-full w-full"
                  src={medImg}
                />
              </div>
              <h2 className="title-font text-2xl font-medium text-white mt-6 mb-3 font-Montserrat">
                Safety Testing
              </h2>
              <p className="leading-relaxed text-base font-Roboto">
                Heavy Metal, Residual Solvents, Pesticides & Microbial
                Contamination Analysis are essential in ensuring the safety of your
                product. This is particularly important to immunocompromised patients.
              </p>
              <a  href='/safety' className="mt-3 text-indigo-400 inline-flex items-center pb-4">
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>
                  </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AnalysisComponent;
