import AnalysisComponent from "../Components/AnalysisComponent";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";

export const Analysis = () => {
  return (
    <>
      <Nav />
      <AnalysisComponent />
      <Footer />
    </>
  );
};

export default Analysis;
