import equImg from "../Images/equipment.png";
import sopImg from "../Images/SOP.png";

export const LabComponent = () => {
  return (
    <>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="container px-5 py-6 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white font-Montserrat">
              Lab
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              We are able to offer the full spectrum of Cannabis analysis to meet all efficacy and quality requirements.
            </p>
          </div>
          <div className="flex flex-wrap m-4">
            <div className="p-4 md:w-1/2">
              <div className="h-full border-2 border-gray-800 rounded-lg overflow-hidden">
                <img
                  className="lg:h-48 md:h-36 w-full object-contain object-center"
                  src={equImg}
                  alt="blog"
                />
                <div className="p-6">
                  <h1 className="title-font text-lg font-medium text-white mb-3">
                    Equipment
                  </h1>
                  <p className="leading-relaxed mb-3">
                    Our Lab uses cutting edge equipment from some of the worlds leading manufacturers.
                    This not only gives us the ability to deliver accurate results but also enables us to have access
                    to a pool of other researchers, scientists and technicians who are constantly working towards advancing cannabis safety and efficacy. 
                  </p>
                  <div className="flex items-center flex-wrap ">
                    <a className="text-indigo-400 inline-flex items-center md:mb-2 lg:mb-0">
                      Learn More
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5l7 7-7 7" />
                      </svg>
                    </a>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/2">
              <div className="h-full border-2 border-gray-800 rounded-lg overflow-hidden">
                <img
                  className="lg:h-48 md:h-36 w-full object-contain object-center"
                  src={sopImg}
                  alt="blog"
                />
                <div className="p-6">
                  <h1 className="title-font text-lg font-medium text-white mb-3">
                    Procedures
                  </h1>
                  <p className="leading-relaxed mb-3">
                  Lab procedures are detailed documents, checklists or guidelines that instruct you how to safely carry out an action in a laboratory environment. 
                  All our scientists and researchers are trained and comply with the highest standards of Good Laboratory Practice and applicable regulations and guidelines.
             
                  </p>
                  <div className="flex items-center flex-wrap">
                    <a className="text-indigo-400 inline-flex items-center md:mb-2 lg:mb-0">
                      Learn More
                      <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5l7 7-7 7" />
                      </svg>
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LabComponent;
