import ContactUSComponent from "../Components/ContactUSComponent";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";

export const ContactUS = () => {
  return <>
  <Nav />
  <ContactUSComponent />
  <Footer />
  </>;
};

export default ContactUS;
