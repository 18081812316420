import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import FAQComponent from "../Components/FAQComponent";
export const FAQ = () => {
  return (
    <>
      <Nav />
      <FAQComponent />
      <Footer />
    </>
  );
};

export default FAQ;
