export const TableColumns = [
  {
    Header: "Item",
    Footer: "Item",
    accessor: "Item",
    disableFilters: true,
    sticky: "left",
  },
  {
    Header: "Name",
    Footer: "Name",
    accessor: "Name",
    sticky: "left",
  },
  {
    Header: "Description",
    Footer: "Description",
    accessor: "Description",
    sticky: "left",
  },
];
