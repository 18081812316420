import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import LabComponent from "../Components/LabComponent";
export const Lab = () => {
  return (
    <>
      <Nav />
      <LabComponent />
      <Footer />
    </>
  );
};

export default Lab;
