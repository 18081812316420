import moistImg from "../Images/02.png";
export const MoistureComponent = () => {
  return (
    <>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="flex flex-col text-center w-full px-5 py-6">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white font-Montserrat">
            Moisture Content
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Moisture content is the percentage, by weight, that is water in your
            cannabis product. Excessive Moisture in Cannabis product can lead to
            quality and safety issues.
          </p>
        </div>
      </section>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="container mx-auto flex px-5 py-6 items-center justify-center flex-col">
        <img
                  alt="content"
                  className="object-cover object-center h-96 w-96"
                  src={moistImg}
                  
                />
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-2xl text-1xl mb-4 font-medium text-white font-Montserrat">
              Moisture Content Analysis in Cannabis
            </h1>
            <p className="leading-relaxed mb-8 font-Roboto">
              Moisture content of Cannabis is a crucial indicator of potential
              microbial contamination. Unlike synthetically produced drugs, the
              cannabis plant is more susceptible to diseases and contaminants.
              In particular, poor drying of the plants during the extraction
              processes can lead to mold or fungal infestation, which in turn
              may pose a risk to the health of the consumer, particularly those
              with immunodeficiencies. Properly dried cannabis contains 10 to 12
              percent moisture. To avoid moisture contents above 12 percent;
              which make dried cannabis more prone to mold, you need to
              precisely determine the moisture content.
              <br/>

              We utilize a moisture balance to accurately determine mositure content in dried cannabis. 
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default MoistureComponent;
