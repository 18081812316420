import pestImg from "../Images/Pest.png";
import mycoImg from "../Images/landing1.png";
import mycoroImg from "../Images/micro.webp";
import mycotoxinImg from "../Images/myco.png";
import toxic from '../Images/toxic.png'
export const SafetyComponent = () => {
  return (
    <>
      <section className="text-gray-400 bg-gray-900 body-font">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mt-6 mb-3 text-white font-Montserrat">
            Safety Testing
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            We are able to offer the full spectrum of Cannabis analysis to meet
            all efficacy and quality requirements.
          </p>
          <section className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-6 mx-auto flex flex-col">
              <div className="lg:w-4/6 mx-auto">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img
                    alt="content"
                    className="object-contain object-center h-full w-full"
                    src={pestImg}
                  />
                </div>
                <div className="flex flex-col sm:flex-row mt-10">
                  <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                    <div className="flex flex-col items-center text-center justify-center">
                      <h2 className="font-medium title-font mt-4 text-white text-lg">
                        Pesticides
                      </h2>
                      <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4" />
                    </div>
                  </div>
                  <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-800 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                    <p className="leading-relaxed text-lg mb-4">
                      Just like for agricultural food products, pesticides are
                      sometimes used to grow the Cannabis. Analysing the pesticide
                      residue of Cannabis will determine if the levels of
                      pesticide fall safety requirements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-6 mx-auto flex flex-col">
              <div className="lg:w-4/6 mx-auto">
                <div className="h-64 overflow-hidden border-t border-gray-400 ">
                  <img
                    alt="content"
                    className="object-contain object-center h-full w-full"
                    src={toxic}
                  />
                </div>
                <div className="flex flex-col sm:flex-row mt-10">
                  <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                    <div className="flex flex-col items-center text-center justify-center">
                      <h2 className="font-medium title-font mt-4 text-white text-lg">
                        Heavy Metals
                      </h2>
                      <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4" />
                    </div>
                  </div>
                  <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-800 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                    <p className="leading-relaxed text-lg mb-4">
                      Heavy metals can be present in cannabis as result of the
                      usage of low-grade pesticides, fertilizers, contaminated
                      water sources, and growing in areas of old industrial
                      sites. Testing for heavy metals in cannabis can not only
                      help identify dangerous product, but also sources
                      contamination within a cultivation and manufacturing
                      process. Mercury, Cadmium, Lead, and Arsenic are all toxic
                      to human health at even small concentrations. Testing for
                      heavy metals can help you determine if your product has
                      come in to contact with these potentially hazardous
                      materials at any point in the growing or manufacturing
                      process. Cannabis is well known for its ability to remove
                      these elements from soil, fertilizers, or other media and
                      accumulate them in their stems, leaves, flowers, and
                      roots. Once consumed or inhaled, these metals disrupt
                      vital processes in the Central Nervous System, Pulmonary
                      System, Reproductive System, and other systems throughout
                      the body. • Mercury (neurological damage) • Cadmium
                      (carcinogenic) • Lead (joint/muscle pain & infertility) •
                      Arsenic (exposure to skin cancer)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-6 mx-auto flex flex-col">
              <div className="lg:w-4/6 mx-auto">
                <div className=" border-t border-gray-400 h-64 overflow-hidden pt-2">
                  <img
                    alt="content"
                    className="object-contain object-center h-full w-full"
                    src={mycotoxinImg}
                  />
                </div>
                <div className="flex flex-col sm:flex-row mt-10">
                  <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                    <div className="flex flex-col items-center text-center justify-center">
                      <h2 className="font-medium title-font mt-4 text-white text-lg">
                        Mycotoxin
                      </h2>
                      <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4" />
                    </div>
                  </div>
                  <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-800 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                    <p className="leading-relaxed text-lg mb-4">
                      Cannabis mycotoxin testing reveals the presence of harmful
                      toxins in your cannabis products that are produced by
                      microbial pathogens. Two classes of mycotoxins, Aflatoxins
                      and Ochratoxins, are produced by molds like Aspergillus.
                      Both classes have carcinogenic and mutagenic properties
                      that can alter our DNA and cause the formation of cancer.
                      Mycotoxin testing will ensure that your products are safe
                      for inhalation and consumption.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-6 mx-auto flex flex-col">
              <div className="lg:w-4/6 mx-auto">
                <div className=" border-t border-gray-400 h-64 overflow-hidden">
                  <img
                    alt="content"
                    className="object-contain object-center h-full w-full"
                    src={mycoImg}
                  />
                </div>
                <div className="flex flex-col sm:flex-row mt-10">
                  <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                    <div className="flex flex-col items-center text-center justify-center">
                      <h2 className="font-medium title-font mt-4 text-white text-lg">
                        Residual Solvents
                      </h2>
                      <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4" />
                    </div>
                  </div>
                  <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-800 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                    <p className="leading-relaxed text-lg mb-4">
                      Residual solvents are organic volatile chemicals used in
                      producing cannabis concentrates. Residual solvents
                      cannabis testing can determine the quality and safety
                      levels of the processed chemicals utilized in the
                      production of cannabis concentrates. Some processing
                      chemicals and solvents used during cannabis extraction are
                      known to have carcinogenic and toxic properties. For this
                      reason, it is important for the processor to know how much
                      of their solvents and chemicals remain in their products.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-6 mx-auto flex flex-col">
              <div className="lg:w-4/6 mx-auto">
                <div className=" border-t border-gray-400 h-64 overflow-hidden">
                  <img
                    alt="content"
                    className="object-contain object-center h-full w-full"
                    src={mycoroImg}
                  />
                </div>
                <div className="flex flex-col sm:flex-row mt-10">
                  <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                    <div className="flex flex-col items-center text-center justify-center">
                      <h2 className="font-medium title-font mt-4 text-white text-lg">
                        Microbial Testing
                      </h2>
                      <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4" />
                    </div>
                  </div>
                  <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-800 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                    <p className="leading-relaxed text-lg mb-4">
                      Cannabis microbial testing detects the presence of
                      pathogenic bacteria and mold that can be lethal in high
                      concentrations. Micro-organisms found in cannabis products
                      have the potential to be very harmful when consumed or
                      inhaled. Aspergillus species cause respiratory infections,
                      while Shiga-toxin producing E. coli and several species of
                      Salmonella can cause gastroenteritis and diarrhoea.
                      Microbiological testing in cannabis can detect specific
                      species of organisms and significantly reduces false
                      positive results compared to traditional plating methods.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default SafetyComponent;
