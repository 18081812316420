import Steps from '../Components/Steps'
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";

export const Process = () => {
  return (
    <>
      <Nav/>
      <Steps />
      <Footer />
    </>
  );
};

export default Process;
