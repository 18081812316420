import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import PotencyComponent from "../Components/PotencyComponent";
export const Potency = () => {
  return (
    <>
      <Nav />
      <PotencyComponent />
      <Footer />
    </>
  );
};

export default Potency;
