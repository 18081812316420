import React, { useMemo } from "react";
import { useTable } from "react-table";
import DATA from "../Data/LL.json";
import { TableColumns } from "./AnalysisTableColumns";

export const AnalysisTable = () => {
  const columns = useMemo(() => TableColumns, []);
  const data = useMemo(() => DATA, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <>
      <div className = 'px-80 bg-gray-900'>
        <table
          {...getTableProps()}
          className="border-2 border-gray-100 bg-gray-900 text-gray-400"
        >
          <thead className=" border-b-2 font-Montserrat px-1 py-1">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className='border-l'>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-gray-900 text-gray-400">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="border-b font-Roboto">
                  {row.cells.map((cell) => {
                    return (
                      <td className="border-r px-4 py-2" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AnalysisTable;
