import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import MoistureComponent from "../Components/MoistureComponent";
export const Moisture = () => {
  return (
    <>
      <Nav />
      <MoistureComponent />
      <Footer />
    </>
  );
};

export default Moisture;
