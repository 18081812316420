import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import SafetyComponent from "../Components/SafetyComponent";
export const Safety = () => {
  return (
    <>
      <Nav />
      <SafetyComponent />
      <Footer />
    </>
  );
};

export default Safety;
